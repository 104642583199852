import React from 'react'
import { graphql } from 'gatsby'

import { Layout, BuyCard, SEO } from '../components/common'

/**
* Single page (/:slug)
*
* This file renders a single page and loads all the content.
*
*/
const ProductPage = ({ data, location }) => {
    const content = data.allProductsJson.edges[0].node
    return (
        <>
            <Layout bgImage={content.header_image}>
                <SEO
                    title={content.seo_title || content.title}
                    image={content.header_image}
                />
                <div className="container">
                    <article className="content">
                        <h1 className="content-title">{content.title}</h1>

                        {/* The main page content */}
                        <section
                            className="content-body load-external-scripts"
                        >
                            <div dangerouslySetInnerHTML={{ __html: content.intro }}/>
                        <section className="products-feed">
                            {content.products.map((product) => (
                                <BuyCard product={product}/>                              
                            ))}
                        </section>
                            
                        </section>
                    </article>
                </div>
            </Layout>
        </>
    )
}

export default ProductPage

export const postQuery = graphql`
query ($slug: String!) {
    allProductsJson(filter: {slug: {eq: $slug}}) {
      edges {
        node {
          header_image
          slug
          title
          intro
          products {
              image
              title
              name
              description
              links {
                  url
                  store
              }
          }
        }
      }
    }
  }
`